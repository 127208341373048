.share-footer{
    margin-top: 40px;
    float: right;
}
.share-app-form{
    min-width: 700px;
    margin-top: 20px;
}
.add-email-container{
    display: flex;
    gap: 20px;
}


/* Shared users */
.user-with-access{
    display: flex;
    align-items: center;
    gap: 20px;
}
.user-with-access-role{
    font-style: italic;
    color: gray;
}
.user-with-access-email{
    flex: 1;
}
.remove-access{
    cursor: pointer;
    color: rgb(188, 12, 12);
}
