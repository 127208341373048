.navigation-container{
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 15px 20px;
    align-items: center;
}
.logo{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 28px;
    font-weight: 900;

}
.navigation-content{
    flex: 1;
    justify-content: end;
    align-items: center;
    display: flex;
}