.custom-header {
    background-color: #f0f0f0;
    color: #333 !important;
    font-weight: bold;
  }
  .custom-header-action .ag-header-cell-label{
    text-align: center;
    justify-content: center;
  }
  .ag-theme-alpine .ag-header-icon.ag-filter-icon {
    /* Change the color to red */
    fill: red;
  }