.modal-children{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width:400px;
  /* min-height: 400px; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 40px;
  background-color: white;
  border-radius: 4px;
}
.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h3{
    margin: 0;
    margin-right: 30px;
}