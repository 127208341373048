.app-details{
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 100%;
}
.app-details-header{
    display: flex;
    gap: 20px;
    align-items: center;
}
.app-details-title{
    flex: 1;
    text-align: left;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 20px;
    color: rgb(45, 44, 44);
}
.table-container{
    flex: 1;
    overflow: auto;
}

/* table grid code */
.table-grid-container{
    margin: 25px 0px;
    background-color: rgb(234, 244, 248);
    padding: 20px;
    border-radius: 4px;
}

.table-grid-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
   
}
.table-grid-title{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    color: rgb(45, 44, 44);
    display: flex;
    align-items: center;
    gap: 5px;

}