.manage-tables{
    flex: 1;
    overflow: auto;
    padding: 40px;
}
.manage-table-row{
    text-transform: capitalize;
}

.action-icon{
    cursor: pointer;
    color: #ff0000;
}

/* delete table modal */
.delete-table-actions{
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: end;
}
.delete-table-title{
    font-weight: 400;
    margin-top: 20px;
}