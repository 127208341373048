.app {
  text-align: center;
  font-family: Roboto;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #e6eff8;
}

.app-outlet{
  flex: 1;
  height: inherit;
  overflow: auto;
}

.error-text {
  color: rgb(209, 37, 37);
  font-size: 12px;
  margin-top: 1em;
  
}
.error{
  color: rgb(209, 37, 37);
  border-color: rgb(209, 37, 37) !important;
}
.spacer {
  height: 10px;
}