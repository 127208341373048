.dashboard-container{
    display: flex;
    height: 100%;
    position: relative;
}

/* Left menu */
.left-menu{
    width: 200px;
    text-align: left;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
}
.menu-section-wrapper{
    flex: 1;
    overflow: auto;
}
.app-list-section{
    margin: 20px 0px;
}
.app-list-title{
    margin-bottom: 5px;
    text-transform: capitalize;
    font-weight: 600;
}
.app-list-items{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
}

/* Right side dashboard content */
.dashboard-content{
    position: relative;
    flex: 1;
    margin: 20px;
    margin-top: 0px;
    padding: 40px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
    /* height: inherit; */
    overflow: auto;
    display: flex;
}