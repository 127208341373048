.vertical-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
  }
  
  .vertical-loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bar {
    width: 4px;
    height: 40px;
    background-color: #afafaf;
    margin: 0 2px;
    animation: loader 1s infinite;
    border-radius: 2px;
  }
  
  @keyframes loader {
    0% {
      height: 10px;
    }
    50% {
      height: 40px;
    }
    100% {
      height: 10px;
    }
  }
  
  .bar1 {
    animation-delay: 0.1s;
  }
  
  .bar2 {
    animation-delay: 0.2s;
  }
  
  .bar3 {
    animation-delay: 0.3s;
  }
  
  .bar4 {
    animation-delay: 0.4s;
  }
  
  .bar5 {
    animation-delay: 0.5s;
  }
  
  .bar6 {
    animation-delay: 0.6s;
  }
  
  .bar7 {
    animation-delay: 0.7s;
  }