.ec-main{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.ec-inner-text{
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    color: #c3c6d4;
}