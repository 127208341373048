.manage-relations{
    min-width: 800px;
    
}
.create-relation-fields{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: end;
}

.relation-list-content{
    display: flex;
    width: 100%;
}
.relation-list-content-item{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.relatons-list-container{
    max-height: 80vh;
    overflow: auto;
}