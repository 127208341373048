.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
}
.login-content{
    width: 400px;
    padding: 40px;
    background-color: #f5f5f5;
    border-radius: 5px;
    text-align: left;
    color: rgb(86, 84, 84);
}