.file-upload {
    border: 1px dashed #b7b5b5;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  
  .drag-active {
    border-color: #007bff;
  }
  
  .file-upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .file-upload-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  
  .file-upload-text {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }
  
  .file-upload-button {
    color: #007bff;
    cursor: pointer;
  }
  
  .file-item {
    background-color: #f0f0f0;
    padding: 5px 10px;
    /* margin-top: 20px; */
    border-radius: 4px;
    font-size: 10px;
  }